import { makeStyles } from '@mui/styles';

import AspectRatioWrapper from './AspectRatioWrapper';
import OverlayActions from './OverlayActions';

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: palette.common.black,
    },
  }),
  { name: 'PlayerLoader' },
);

export default function PlayerLoader(props) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <AspectRatioWrapper className={className} wrapperClassName={classes.root}>
      <OverlayActions loading />
    </AspectRatioWrapper>
  );
}
